/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../../styles/InvoiceCode.module.css';
import loginStyles from '../../styles/login.module.css';
import { useInvoiceState } from '../../context/state';
import * as api from '../../helper/api';

export default function PopUpLogin({
  showModal, triggerModal,
}) {
  const [state] = useInvoiceState();
  const [disable] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  // const [err, setErr] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [errOTP, setErrOTP] = useState(false);
  const [otp, setOtp] = useState({
    number1: '',
    number2: '',
    number3: '',
    number4: '',
    number5: '',
    number6: '',
    number7: '',
  });

  const [phoneNumber, setPhoneNumber] = useState(state?.customerNumber);
  const [authyId, setAuthyId] = useState('');

  const onChangePhoneNumber = (e) => {
    const number = e.target.value;
    if (number.charAt(0) !== '8') setIsInvalid(true);
    else setIsInvalid(false);
    setPhoneNumber(number);
  };

  const deletePhoneNumber = () => {
    setPhoneNumber('');
    setIsInvalid(false);
  };

  const loginHandler = async (e) => {
    e?.preventDefault();
    // setErr(false);
    if (phoneNumber === '') {
      setIsInvalid(true);
    } else {
      try {
        // setLoading(true);
        const { data } = await api.login(phoneNumber);
        setAuthyId(data.userInfo.authyId);
        setIsSubmit(true);
        // setLoading(false);
      } catch (error) {
        // setLoading(false);
        setIsSubmit(false);
      }
    }
  };

  const verify = async (e) => {
    e.preventDefault();
    try {
      // setLoading(true);
      const mapOtp = `${otp.number1}${otp.number2}${otp.number3}${otp.number4}${otp.number5}${otp.number6}${otp.number7}`;
      const { data } = await api.verifyLogin(authyId, phoneNumber, mapOtp);
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
      setIsVerify(true);
      // setLoading(false);
      setOtp({
        number1: '',
        number2: '',
        number3: '',
        number4: '',
        number5: '',
        number6: '',
        number7: '',
      });
    } catch (error) {
      // setErr(true);
      setErrOTP(true);
      setOtp({
        number1: '',
        number2: '',
        number3: '',
        number4: '',
        number5: '',
        number6: '',
        number7: '',
      });
      // setLoading(false);
    }
  };

  const handlePaste = (event) => {
    const text = event.clipboardData.getData('text');
    if (text.length === 7) {
      setOtp({
        number1: text[0],
        number2: text[1],
        number3: text[2],
        number4: text[3],
        number5: text[4],
        number6: text[5],
        number7: text[6],
      });
    }
  };

  const inputfocus = (elmnt) => {
    const targetName = elmnt.target.name;
    if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
      const mapOtp = { ...otp };
      mapOtp[targetName] = '';
      setOtp(mapOtp);
    } else if (/\d/.test(elmnt.key)) {
      const mapOtp = { ...otp };
      if (mapOtp[targetName] !== '') {
        mapOtp[targetName] = elmnt.key;
        setOtp(mapOtp);
      }

      let next = elmnt.target.tabIndex;
      const getNext = (currentOtp) => {
        let nextTab = 0;
        let isEmpty = false;
        const numbers = ['number1', 'number2', 'number3', 'number4', 'number5', 'number6', 'number7'];
        for (let i = 0; i < numbers.length; i++) {
          if (!currentOtp[numbers[i]]) {
            isEmpty = true;
          } else if (!isEmpty) nextTab = i + 1;
        }
        return nextTab;
      };

      next = getNext(mapOtp);
      if (next <= 7) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  useEffect(() => {
    if (countdown > 0 && isSubmit) {
      const interval = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearInterval(interval);
    }
  }, [countdown, isSubmit]);

  // if (loading) return <Loading />;
  if (isVerify) {
    return (
      <>
        <div aria-hidden onClick={disable ? null : triggerModal} className="modal" style={showModal ? { display: 'block' } : {}} />
        <div className="modal-login">
          <div style={{ marginTop: '40px', textAlign: 'center' }}>
            <img alt="completed" src="/status/completed.png" />
            <p
              style={{
                marginTop: '15px',
                marginBottom: '10px',
                fontSize: '20px',
                lineHeight: '24px',
                fontWeight: '500',
              }}
              className={styles.loginDesc}
            >
              Nomor Anda Berhasil Terdaftar!

            </p>
            <p className={styles.loginDesc}>Klik lanjut untuk melanjutkan pembayaran.</p>
          </div>
          <button
            onClick={() => triggerModal()}
            style={{
              cursor: 'pointer', height: '44px', marginTop: '100px',
            }}
            className={styles.footerButton}
            type="submit"
          >
            Lanjut
          </button>
        </div>
      </>
    );
  }

  if (isSubmit) {
    return (
      <>
        {errOTP && (
        <>
          <div
            aria-hidden
            onClick={disable ? null : triggerModal}
            className="modal"
            style={showModal ? { display: 'block', zIndex: '100' } : {}}
          />
          <div style={{ zIndex: '101' }} className="modal-login">
            <p
              style={{
                padding: '0px',
                fontSize: '27px',
                fontWeight: '500',
                lineHeight: '32px',
                color: '#14151A',
                fontStyle: 'normal',
                marginBottom: '16px',
              }}
              className={styles.mainContentTotalTitle}
            >
              Verifikasi Gagal
            </p>
            <p className={styles.loginDesc}>
              Kode OTP yang Anda masukkan salah. Silahkan coba lagi
            </p>

            <button
              onClick={() => setErrOTP(false)}
              style={{
                cursor: 'pointer', display: isVerify ? 'none' : 'block', height: '44px', marginTop: '20px',
              }}
              className={styles.footerButton}
              type="submit"
            >
              Oke
            </button>
          </div>
        </>

        )}
        <div aria-hidden onClick={disable ? null : triggerModal} className="modal" style={showModal ? { display: 'block' } : {}} />
        <div className="modal-login">
          <div className={styles.loginHeader}>
            <img aria-hidden onClick={() => setIsSubmit(false)} alt="back" src="/back.svg" />
            <p className={styles.loginTitle}>Verifikasi Nomor</p>
          </div>
          <div style={{ textAlign: 'center' }}>
            <p className={styles.loginDesc}>Masukkan Kode OTP</p>
            <p className={styles.loginDesc}>Kode Verifikasi akan di kirim melalui Whatsapp</p>
            <p className={styles.loginDesc}>
              Ke +62
              {phoneNumber}
            </p>
          </div>

          <form
            style={{
              display: isVerify ? 'none' : 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onSubmit={verify}
          >
            <div className={loginStyles.formContainer}>
              <div className={loginStyles.otpForm}>
                <input
                  type="number"
                  onPaste={handlePaste}
                  value={otp?.number1}
                  name="number1"
                  maxLength="1"
                  className={loginStyles.otpInput}
                  onChange={(e) => {
                    const targetValue = e.target.value[0];
                    const mapOtp = { ...otp };
                    mapOtp.number1 = targetValue;
                    setOtp(mapOtp);
                  }}
                  tabIndex="1"
                  onKeyUp={(e) => inputfocus(e)}
                />
                <input
                  type="number"
                  onPaste={handlePaste}
                  value={otp?.number2}
                  maxLength="1"
                  name="number2"
                  className={loginStyles.otpInput}
                  onChange={(e) => {
                    const targetValue = e.target.value[0];
                    const mapOtp = { ...otp };
                    mapOtp.number2 = targetValue;
                    setOtp(mapOtp);
                  }}
                  tabIndex="2"
                  onKeyUp={(e) => inputfocus(e)}
                />
                <input
                  type="number"
                  onPaste={handlePaste}
                  value={otp?.number3}
                  maxLength="1"
                  name="number3"
                  className={loginStyles.otpInput}
                  onChange={(e) => {
                    const targetValue = e.target.value[0];
                    const mapOtp = { ...otp };
                    mapOtp.number3 = targetValue;
                    setOtp(mapOtp);
                  }}
                  tabIndex="3"
                  onKeyUp={(e) => inputfocus(e)}
                />
                <input
                  type="number"
                  onPaste={handlePaste}
                  maxLength="1"
                  value={otp?.number4}
                  name="number4"
                  className={loginStyles.otpInput}
                  onChange={(e) => {
                    const targetValue = e.target.value[0];
                    const mapOtp = { ...otp };
                    mapOtp.number4 = targetValue;
                    setOtp(mapOtp);
                  }}
                  tabIndex="4"
                  onKeyUp={(e) => inputfocus(e)}
                />
                <input
                  type="number"
                  onPaste={handlePaste}
                  value={otp?.number5}
                  maxLength="1"
                  name="number5"
                  className={loginStyles.otpInput}
                  onChange={(e) => {
                    const targetValue = e.target.value[0];
                    const mapOtp = { ...otp };
                    mapOtp.number5 = targetValue;
                    setOtp(mapOtp);
                  }}
                  tabIndex="5"
                  onKeyUp={(e) => inputfocus(e)}
                />
                <input
                  type="number"
                  onPaste={handlePaste}
                  value={otp?.number6}
                  maxLength="1"
                  name="number6"
                  className={loginStyles.otpInput}
                  onChange={(e) => {
                    const targetValue = e.target.value[0];
                    const mapOtp = { ...otp };
                    mapOtp.number6 = targetValue;
                    setOtp(mapOtp);
                  }}
                  tabIndex="6"
                  onKeyUp={(e) => inputfocus(e)}
                />
                <input
                  type="number"
                  onPaste={handlePaste}
                  maxLength="1"
                  value={otp?.number7}
                  name="number7"
                  className={loginStyles.otpInput}
                  onChange={(e) => {
                    const targetValue = e.target.value[0];
                    const mapOtp = { ...otp };
                    mapOtp.number7 = targetValue;
                    setOtp(mapOtp);
                  }}
                  tabIndex="7"
                  onKeyUp={(e) => inputfocus(e)}
                />
              </div>
            </div>
            <h6 className={loginStyles.desc}>
              Belum dapat kode ?
              {' '}
              <span
                aria-hidden
                onClick={() => {
                  if (countdown === 0) {
                    loginHandler();
                    setCountdown(60);
                  }
                }}
                style={{
                  color: `${countdown === 0 ? 'black' : '#DCDCDC'}`,
                  textDecoration: 'underline',
                  cursor: `${countdown === 0 ? 'pointer' : 'default'}`,
                }}
              >
                Kirim Ulang Kode
              </span>
              {' '}
              <Timer countdown={countdown} />
            </h6>
            <button
              tabIndex="8"
              style={{
                cursor: 'pointer', display: isVerify ? 'none' : 'block', height: '44px', marginTop: '100px',
              }}
              className={styles.footerButton}
              type="submit"
            >
              Verifikasi
            </button>
          </form>
        </div>
      </>
    );
  }

  return (
    <>
      <div aria-hidden onClick={disable ? null : triggerModal} className="modal" style={showModal ? { display: 'block' } : {}} />
      <div className="modal-login">
        <div className={styles.loginHeader}>
          <img style={{ width: '15px' }} aria-hidden onClick={() => triggerModal()} alt="back" src="/cancel.svg" />
          <p className={styles.loginTitle}>Masukkan Nomor Telepon</p>
        </div>
        <p className={styles.loginDesc}>Kode Verifikasi akan di kirimkan ke nomor Anda</p>
        <form onSubmit={loginHandler}>
          <div className={loginStyles.formContainer}>
            <label style={{ color: `${isInvalid ? 'red' : ''}` }} className={loginStyles.formTitle}>No Telepon</label>
            <div
              style={{ marginTop: '5px', borderBottom: isInvalid ? '2px solid red' : '' }}
              className={loginStyles.mainContentPaymentDropdown}
            >
              <span className={loginStyles.countryCode}>
                +62

              </span>
              <input
                type="number"
                value={phoneNumber}
                onChange={onChangePhoneNumber}
                className={loginStyles.mainContentVoucherInput}
                style={phoneNumber ? {} : { fontSize: '12px', color: '#CED2D9' }}
                placeholder="Masukkan nomor telepon"
              />
              <div style={{ flexGrow: 1 }} />
              <div aria-hidden onClick={deletePhoneNumber}>
                <img alt="cancel" src="/cancel.svg" />
              </div>
            </div>
            <p style={{
              display: isInvalid ? 'block' : 'none', color: 'red', fontSize: '14px', marginTop: '5px',
            }}
            >
              Nomor Tidak Valid
            </p>
          </div>
          <button disabled={isInvalid} style={{ marginTop: '100px', cursor: 'pointer', height: '44px' }} className={styles.footerButton} type="submit">
            Lanjut
          </button>
        </form>
      </div>
    </>
  );
}

function Timer({ countdown }) {
  const minutes = Math.floor(countdown / 60);
  const seconds = countdown - minutes * 60;
  function strPadLeft(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }
  return (
    <span>
      {`${strPadLeft(minutes, '0', 2)}:${strPadLeft(seconds, '0', 2)}`}
    </span>
  );
}
